/* You can add global styles to this file, and also import other style files */
// @import "~bootstrap/scss/bootstrap.scss";
@import "../src/assets/ad/css/main.css";
@import "../src/assets/ad/css/wireframe-theme.min.css";


.versionlink{
  color:transparent;
}

.body-1, .intervju{
  overflow-x: hidden;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 6px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #000;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.link-container.link-container-6:hover{
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

body.intervju {
  background-color: #dee3e0;
}
body.ord-color {
  background-color: #e2dbeb;
}
body.quizz-color {
  background-color: #d5d8e6;
}
body.material-color {
  background-color: #dbdcc8;
}
body.reg-color {
  background-color: #dbdcc8;
}
.text-black{
  color: #000 !important;
}
// @media screen and (max-width: 39.0625rem) {
//   body{
//       position: absolute ;
//   }

//   }
h1.h1-dekor{
  font-size: 21.68vw;
}
.placeholderblock{
  min-height: 80rem;
}
p.paragraph.p-standard.paragraph-4.white:hover {
  color: #fff;
  text-decoration: none;
}
.sitelogo_link:hover{
  text-decoration: none;
}
.responsive-video{
  margin-bottom: 2rem;
}

li a{
  text-decoration: underline;
}

.modal-dialog{width: 620px!important;}

a:hover {
  color:transparent;
}
.paragraph-text-60 a,.paragraph-text-61 a{color:#000!important}
