/* Text Elements */

h1.heading-1 {
  color: #fff;
  font-weight: 300;
}

h1.heading-1.site-title {
  min-width: 0;
  font-size: 31px;
}

h1.bigbig {
  display: block;
  font-weight: 700;
  font-size: 15.67vw;
  line-height: 12.7vw;
}

h1.bigbig.bigbigbig {
  font-size: 19vw;
  line-height: 19vw;
}

h1.bigbig.bigbigbig.white {
  color: #fff;
}

h1 span.heading-text-2 {
  color: #b2a47b;
}

h1.bigbig.white {
  color: #fff;
}

h1.big.bigbig {
  font-size: 13vw;
}

h1.bigbig-1 {
  font-weight: 700;
  font-size: 15.67vw;
  line-height: 12vw;
}

h1.big-1.bigbig-1.vad-pub.white-1 {
  margin-bottom: 30px;
  min-width: 800px;
  color: #b2a47b;
  font-size: 11.3vw;
}

h1.big-1.bigbig-1.bottom.vad-pub.white-1 {
  color: #fff;
}

h1.big-1.bigbig-1.top.vad-pub.white-1 {
  min-width: 0;
}

h1.h1-dekor {
  margin-top: -4vw;
  width: 100vw;
  height: auto;
  color: #fff;
  text-align: center;
  text-transform: lowercase;
  word-spacing: 0;
  letter-spacing: -4px;
  font-weight: 900;
  font-size: 23.68vw;
  line-height: 23vw;
}

h1.h1-intro-guide {
  color: #fff;
  font-weight: 700;
  font-size: 56px;
  line-height: 58px;
}

h1.h1-overrubrik-guide {
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
}

h1.h1-overrubrik-guide.white {
  color: #fff;
}

h1.h1-about {
  margin-top: 50px;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 8.89vw;
  line-height: 9.52vw;
}

h1.h1-about.i1 {
  margin-top: 30px;
}

h1.h1-dekor.intervjuer {
  font-size: 20.68vw;
}

h1.big-1.bigbig-1.bottom.vad-pub.white-1:hover {
  font-weight: 700;
}

h2.h2-bold {
  color: #000;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
}

h2.citat.h2-bold {
  margin-top: -20px;
  margin-bottom: 40px;
  color: #fff;
  font-weight: 400;
  font-size: 12.88vw;
  line-height: 12.88vw;
}

h2.h2-bold-1 {
  color: #000;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
}

h2.h2-regular.heading-3 {
  margin-top: 30px;
  margin-right: 10px;
  margin-left: 20px;
  font-weight: 700;
  font-size: 28px;
}

h2.h2-regular {
  font-weight: 700;
}

h2.h2-about {
  font-weight: 700;
  font-size: 20px;
}

h2.h2-big-about {
  margin-top: 0;
  margin-bottom: 8px;
  padding-top: 0;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
}

h2.h2-bold.start-guide {
  margin-bottom: 0;
  color: #45606c;
}

h2.h2-bold.space {
  margin-top: 0;
}

h2.h2-regular.heading-3.white {
  color: #fff;
}

h2.big.h2-regular.heading-3 {
  margin-bottom: 20px;
  font-size: 32px;
}

h2.h2-bold.white {
  color: #fff;
}

h2.h2-bold.white:hover {
  color: #fff;
  text-decoration: none;
}

h2.h2-bold:hover {
  color: #000;
  text-decoration: none;
}

h2:hover {
  color: #000;
  text-decoration: none;
}

h2.citat.h2-bold:hover {
  color: #fff;
  text-decoration: none;
}

h2.h2-bold-1:hover {
  color: #000;
  text-decoration: none;
}

h2.h2-regular.heading-3:hover {
  color: #000;
  text-decoration: none;
}

h2.h2-bold.start-guide:hover {
  color: #849c8f;
}

h2.h2-bold.white:active {
  text-decoration: none;
}

h3.h3-about {
  margin-top: 32px;
  margin-bottom: 0;
  color: #000;
  text-align: left;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

h3 span.heading-text-1 {
  font-weight: 500;
}

h3.h3-about.medium {
  font-weight: 500;
}

h3.f1-3.h3-about.medium {
  margin-top: 32px;
  margin-bottom: 0;
}

h3.f3.h3-about {
  margin-bottom: 20px;
}

h3.h3-about.k1-2 {
  margin-top: 10px;
  text-align: center;
}

h6.heading-2 {
  margin-bottom: 0;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 17px;
}

p.paragraph.paragraph-1 {
  font-size: 19px;
}

p.paragraph.ingress.paragraph-1 {
  margin-top: 8.76vw;
  font-size: 24px;
  line-height: 36px;
}

p.paragraph.p-std {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 30px;
  min-width: 50%;
  max-width: 650px;
  color: #000;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

p.paragraph.p-std.white {
  margin-bottom: 0;
  min-width: 50%;
  max-width: 650px;
  color: #fff;
  text-align: left;
}

p.paragraph.footer.p-std.white {
  margin-bottom: 16px;
  width: auto;
  text-align: center;
}

p.paragraph.paragraph-2 {
  margin-right: auto;
  margin-left: auto;
  max-width: 700px;
  width: auto;
}

p.paragraph.citat.paragraph-2 {
  position: static;
  top: -200px;
  clear: none;
  margin-right: 0;
  margin-left: 0;
  max-width: 600px;
  font-size: 24px;
}

p.paragraph span.paragraph-text-2 {
  font-weight: 600;
}

p.paragraph.paragraph-3 {
  color: #fff;
  font-size: 14px;
}

p.paragraph.paragraph-3.referens {
  margin-top: 50px;
  font-size: 16px;
}

p.paragraph.paragraph-1-1 {
  font-size: 19px;
}

p.paragraph span.paragraph-text-3 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-4 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-5 {
  font-weight: 700;
}

p.paragraph span.h2-bold {
  font-weight: 700;
  font-size: 1.875rem;
}

p.paragraph.ingress-1.paragraph-1-1.vad-pub {
  margin-top: 40px;
  max-width: 800px;
  width: 100%;
  font-size: 28px;
}

p.paragraph.p-standard.paragraph-4 {
  margin: 0 15px 10px 20px;
  min-height: auto;
  height: auto;
  border-bottom-width: 0;
  border-bottom-style: none;
  font-size: 18px;
  line-height: 28px;
}

p.paragraph.p-standard-bold {
  margin-right: 40px;
  color: #000;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

p.paragraph.ingress {
  margin-top: 40px;
  margin-bottom: 50px;
  color: #000;
  font-size: 28px;
}

p.paragraph.ingress.white {
  color: #fff;
}

p.paragraph.ingress-1.paragraph-1-1.top.vad-pub {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 24px;
  line-height: 36px;
}

p.paragraph span.h2-regular {
  font-size: 1.875rem;
}

p.paragraph span.paragraph-text-7 {
  font-size: 1.875rem;
}

p.paragraph span.paragraph-text-6 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-9 {
  font-weight: 400;
}

p.paragraph.p-standard {
  padding-bottom: 50px;
  border-bottom-width: .1875rem;
  border-bottom-style: solid;
  font-size: 19px;
  line-height: 32px;
}

p.paragraph span.h3-about {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.6;
}

p.paragraph span.paragraph-text-10 {
  font-weight: 700;
}

p.paragraph span.h2-text {
  text-transform: none;
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph.ingress-about {
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 36px;
}

p.paragraph.p-standard-about {
  margin-bottom: 0;
  color: #000;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

p.paragraph span.paragraph-text-8 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-11 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-12 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-13 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-14 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-15 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-16 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-17 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-18 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-19 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-20 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-21 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-22 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-23 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-24 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph span.paragraph-text-25 {
  font-weight: 700;
  font-size: 1.25rem;
}

p.paragraph.ingress.no-space {
  margin-bottom: 0;
  font-size: 26px;
  line-height: 36px;
}

p.paragraph.ingress-guide {
  margin-top: 20px;
  margin-bottom: 16px;
  max-width: 600px;
  font-size: 24px;
  line-height: 36px;
}

p.paragraph span.paragraph-text-33 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-34 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-35 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-36 {
  color: #000;
}

p.paragraph span.paragraph-text-37 {
  color: #000;
}

p.paragraph span.paragraph-text-38 {
  color: #000;
}

p.paragraph span.paragraph-text-39 {
  font-weight: 700;
}

p.paragraph.p-std.strategi {
  margin-bottom: 8px;
  text-align: left;
}

p.paragraph span.paragraph-text-40 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-41 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-42 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-43 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-44 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-45 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-46 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-47 {
  font-weight: 700;
}

p.paragraph.p-space-above.p-std {
  margin-top: 20px;
  margin-bottom: 0;
  max-width: 510px;
  font-weight: 700;
}

p.paragraph span.paragraph-text-48 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-49 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-50 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-51 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-52 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-53 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-54 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-55 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-57 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-58 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-59 {
  font-weight: 700;
}

p.paragraph.p-std.var-det-fix {
  margin-top: 30px;
  margin-bottom: 20px;
  max-width: 510px;
}

p.paragraph span.paragraph-text-60 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-61 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-62 {
  font-weight: 700;
}

p.paragraph.bold-guide.p-std {
  font-weight: 700;
}

p.paragraph.referens-about {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom-width: .1875rem;
  border-bottom-style: solid;
  line-height: 28px;
}

p.paragraph.p-image-about {
  margin-top: 5px;
  margin-bottom: 40px;
}

p.paragraph.last.p-standard-about {
  margin-bottom: 0;
  padding-bottom: 0;
}

p.paragraph.link-text-about.p-standard-about {
  margin-bottom: 0;
  color: #000;
  font-weight: 700;
}

p.paragraph span.paragraph-text-26 {
  font-weight: 400;
}

p.paragraph.p-standard-about.space-down {
  margin-bottom: 40px;
}

p.paragraph.bold-about.p-standard-about {
  font-weight: 700;
}

p.paragraph.bold-about.p-space-above-40.p-standard-about {
  margin-top: 40px;
}

p.paragraph.f1-4-space-above.p-standard-about {
  margin-top: 5px;
}

p.paragraph a.paragraph-text-27 {
  color: #000;
}

p.paragraph.p-standard-about.tabell {
  display: inline-block;
  width: 50%;
  text-align: left;
}

p.paragraph.p-standard-about.right.tabell {
  width: 10%;
  text-align: right;
}

p.paragraph.left.p-standard-about.tabell {
  width: 80%;
}

p.paragraph.f3.left.p-standard-about.tabell {
  width: 100%;
  font-weight: 400;
}

p.paragraph.bold.f3.left.p-standard-about.tabell {
  font-weight: 700;
}

p.paragraph span.paragraph-text-28 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-29 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-30 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-31 {
  font-weight: 700;
}

p.paragraph.f3.h1-2.left.p-standard-about.tabell {
  font-weight: 400;
}

p.paragraph span.paragraph-text-63 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-64 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-65 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-66 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-67 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-68 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-69 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-70 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-71 {
  font-weight: 500;
}

p.paragraph span.paragraph-text-72 {
  font-weight: 500;
}

p.paragraph span.paragraph-text-73 {
  font-weight: 500;
}

p.paragraph span.paragraph-text-74 {
  font-weight: 500;
}

p.paragraph span.paragraph-text-75 {
  font-weight: 400;
}

p.paragraph span.paragraph-text-76 {
  font-weight: 500;
}

p.paragraph span.paragraph-text-77 {
  font-weight: 500;
}

p.paragraph span.paragraph-text-78 {
  font-weight: 500;
}

p.paragraph span.paragraph-text-79 {
  font-weight: 500;
  font-style: normal;
}

p.paragraph.k1-2.p-standard-about {
  margin-right: auto;
  margin-left: auto;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

p.paragraph.k5.p-standard-about {
  max-width: 95%;
  text-align: right;
}

p.paragraph span.paragraph-text-80 {
  font-weight: 400;
}

p.paragraph span.paragraph-text-81 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-82 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-83 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-84 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-85 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-86 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-87 {
  font-weight: 700;
}

p.paragraph.j1-1.p-standard-about {
  margin-top: 32px;
}

p.paragraph.b0.p-standard-about {
  margin-bottom: 40px;
}

p.paragraph.ingress.no-space.white {
  font-size: 24px;
}

p.paragraph.citat.index.paragraph-2 {
  color: #fff;
}

p.paragraph.white {
  color: #fff;
}

p.paragraph span.paragraph-text-1 {
  color: #000;
}

p.paragraph.guide.p-std {
  margin-bottom: 40px;
}

p.paragraph.alt.p-std {
  margin-bottom: 0;
}

p.paragraph.no-space-lower.p-standard-bold {
  margin-bottom: 0;
}

p.paragraph.no-space-lower.p-space-above.p-std {
  margin-bottom: 0;
}

p.paragraph.no-space-lower.p-std {
  margin-bottom: 0;
}

p.paragraph.p-std.space-down {
  margin-bottom: 20px;
}

p.paragraph.l-0.p-std.space-down {
  margin-top: 20px;
}

p.paragraph.p-standard-about.reg {
  margin-bottom: 40px;
}

p.paragraph.p-standard.paragraph-4.white {
  padding-bottom: 0;
}

p.paragraph.p-standard.paragraph-4.varde {
  margin-bottom: 0;
  padding-bottom: 0;
}

p.paragraph.p-std.space-down-10 {
  margin-bottom: 10px;
}

p.paragraph span.paragraph-text-93 {
  font-style: italic;
}

p.paragraph span.paragraph-text-94 {
  font-style: italic;
}

p.paragraph span.paragraph-text-95 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-96 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-97 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-98 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-99 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-100 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-101 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-102 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-103 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-104 {
  font-weight: 700;
}

p.paragraph span.paragraph-text-105 {
  font-weight: 700;
}

p.paragraph.down36.ingress-guide {
  margin-bottom: 36px;
}

p.paragraph.down28.p-std.space-down {
  margin-bottom: 28px;
}

p.paragraph.alt.ingress-guide.no-space-up {
  margin-top: 0;
  margin-bottom: 0;
}

p.paragraph.p-space-down-40.p-std {
  margin-bottom: 18px;
}

p.paragraph.p-std.spacedown28 {
  margin-bottom: 28px;
}

p.paragraph.no-space-lower.p-standard-bold.white {
  padding-top: 10px;
}

p.paragraph.link.p-std {
  text-decoration: none;
}

p.paragraph.p-std:hover {
  color: #000;
  text-decoration: none;
}

p.paragraph.p-std.white:hover {
  color: #fff;
  text-decoration: none;
}

p.paragraph:hover {
  color: #000;
  text-decoration: none;
}

p.paragraph.ingress.paragraph-1:hover {
  color: #000;
  text-decoration: none;
}

p.paragraph.citat.paragraph-2:hover {
  color: #fff;
  text-decoration: none;
}

p.paragraph.paragraph-3.referens:hover {
  color: #fff;
  text-decoration: none;
}

p.paragraph.ingress:hover {
  color: #000;
  text-decoration: none;
}

p.paragraph.ingress.white:hover {
  color: #fff;
  text-decoration: underline;
}

p.paragraph.p-standard.paragraph-4:hover {
  color: #000;
  text-decoration: none;
}

p.paragraph.p-standard-bold:hover {
  color: #000;
  text-decoration: underline;
}

p.paragraph.ingress-1.paragraph-1-1.top.vad-pub:hover {
  color: #000;
  text-decoration: none;
}

p.paragraph.p-standard:hover {
  color: #000;
  text-decoration: none;
}

p.paragraph.ingress-about:hover {
  color: #000;
  text-decoration: none;
}

p.paragraph.ingress-guide:hover {
  text-decoration: none;
}

p.paragraph.link-text-about.p-standard-about:hover {
  text-decoration: underline;
}

p.paragraph a.paragraph-text-27:hover {
  text-decoration: underline;
}

p.paragraph.p-std.strategi:hover {
  text-decoration: none;
}

p.paragraph span.paragraph-text-59:hover {
  text-decoration: underline;
}

p.paragraph.p-standard-about:hover {
  text-decoration: none;
}

p.paragraph.no-space-lower.p-standard-bold.white:hover {
  color: #fff;
}

p.paragraph.link.p-std.white:hover {
  text-decoration: underline;
}

p.paragraph.link.p-std:hover {
  text-decoration: underline;
}

p.paragraph.footer.p-std.white:active {
  text-decoration: none;
}

p.paragraph.p-std.white:active {
  text-decoration: none;
}

ul.unorder-list.unordered-list-1 {
  margin-bottom: 40px;
  list-style-position: outside;
}

ul.unorder-list.unordered-list-2 {
  margin-bottom: 0;
  list-style-position: outside;
}

ul.unorder-list.unordered-list-3 {
  margin-top: 0;
}

ul.unorder-list.unordered-list-4 {
  margin-top: 0;
  margin-bottom: 32px;
}

ul.unorder-list.referens.unordered-list-4 {
  margin-bottom: 0;
  padding-bottom: 40px;
  padding-left: 0;
  border-bottom-width: .1875rem;
  border-bottom-style: solid;
  list-style-position: outside;
  list-style-type: none;
}

ul.unorder-list.f1.unordered-list-4 {
  margin-bottom: 20px;
}

ul.unorder-list.f1.no-space.unordered-list-4 {
  margin-bottom: 0;
}

ul.unorder-list.f14b.unordered-list-4 {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 0;
}

ul.unorder-list.f3.unordered-list-4 {
  margin-bottom: 20px;
}

ul.unorder-list.f3.g1.g1-plus.unordered-list-4 {
  margin-bottom: 40px;
}

ul.unorder-list.space-under-h1.unordered-list-4 {
  margin-bottom: 20px;
}

ul.unorder-list.k1.unordered-list-4 {
  margin-bottom: 32px;
}

ul.unorder-list.k5.unordered-list-4 {
  margin-top: 20px;
}

ul.unorder-list.k9.unordered-list-4 {
  margin-top: 20px;
  margin-bottom: 0;
}

ul.unorder-list.k9.lower.unordered-list-4 {
  margin-bottom: 32px;
}

ul.unorder-list.k3.unordered-list-4 {
  margin-top: 20px;
}

ul.unorder-list.no-space.unordered-list-4 {
  margin-bottom: 0;
}

ul.unorder-list.h1-1.unordered-list-4 {
  padding-top: 10px;
}

ul.unorder-list.k2-1.unordered-list-4 {
  margin-top: 20px;
}

ul.unorder-list.spacedown40.unordered-list-2 {
  margin-bottom: 28px;
}

blockquote.citat-about {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom-width: .1875rem;
  border-bottom-style: solid;
  font-size: 30px;
  line-height: 42px;
}

blockquote.citat-about.k5 {
  margin-bottom: 5px;
  padding-bottom: 0;
  border-bottom-width: 0;
  border-bottom-style: none;
}

blockquote.c2.citat-about {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom-width: 0;
  border-bottom-style: none;
}

a.link-text.nav-link {
  padding: 0;
  color: #fff;
}

a.link-text.nav-link.nav-link-tl {
  margin-right: 10px;
  margin-left: 10px;
  padding: 0;
  max-width: none;
  border-top: .25rem solid transparent;
  border-bottom: .25rem solid transparent;
  color: #fff;
  text-decoration: none;
  font-size: 17px;
}

a.link-text.nav-link-tl {
  text-transform: uppercase;
}

a.link-text.text-link-1 {
  color: #fff;
}

a.link-text span.text-link-text-1 {
  font-weight: 900;
  font-size: 1.1875rem;
}

a.link-text span.text-link-text-2 {
  font-weight: 900;
  font-size: 1.1875rem;
}

a.link-text span.text-link-text-3 {
  font-weight: 900;
  font-size: 1.1875rem;
}

a.link-text span.normal.text-link-text-2 {
  font-weight: 400;
  font-size: 1rem;
}

a.link-text.nav-link.nav-link-tl:visited {
  padding-bottom: 0;
}

a.link-text.nav-link-tl:focus {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 19px;
}

a.link-text.nav-link.nav-link-tl:focus {
  padding-bottom: 0;
  border-top: .25rem solid transparent;
  border-bottom-width: .25rem;
  border-bottom-style: solid;
  color: #fff;
  font-weight: 400;
  font-size: 17px;
}

a.link-text.nav-link-tl:hover {
  color: #fff;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 19px;
}

a.link-text.nav-link.nav-link-tl:hover {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  border-top: .25rem solid transparent;
  border-bottom: .25rem solid #fff;
  border-radius: 0;
  color: #fff;
  font-weight: 400;
  font-size: 17px;
}

a.link-text.nav-link-tl:active {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 19px;
}

a.link-text.nav-link.nav-link-tl:active {
  padding-bottom: 0;
  border-top: .25rem solid transparent;
  border-bottom: .25rem solid #fff;
  color: #fff;
  font-weight: 400;
  font-size: 17px;
}

/* Interaction Elements */

a.link-button.btn {
  width: 184px;
  height: 52px;
  border-width: 0;
  border-style: none;
  border-radius: 26px;
  background-color: #e05439;
}

a.link-button.btn.btn-text-tl {
  display: block;
  margin-top: 0;
  padding: 0;
  width: auto;
  height: auto;
  border-radius: 0;
  background-color: transparent;
  color: #fff;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;

  justify-self: auto;
  grid-area: auto/ auto / auto / auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
}

form.form-container.form-container-1 {
  -moz-box-sizing: border-box;
       box-sizing: border-box;
}

input[type=text].input-1 {
  margin-bottom: 10px;
  padding-right: 1px;
  padding-left: 10px;
  width: 100%;
  height: 40px;
  border-width: 0;
  border-style: none;
  border-radius: 6px;
}

input[type=text].input-2 {
  margin-bottom: 10px;
  padding-left: 11px;
  width: 100%;
  height: 40px;
  border-width: 0;
  border-style: none;
  border-radius: 6px;
}

input[type=text].input-3 {
  margin-bottom: 10px;
  padding-left: 10px;
  width: 100%;
  height: 40px;
  border-width: 0;
  border-style: none;
  border-radius: 6px;
}

input[type=email].email-1 {
  margin-bottom: 40px;
  padding-left: 10px;
  width: 100%;
  height: 40px;
  border-width: 0;
  border-style: none;
  border-radius: 6px;
}

label.checkbox.checkbox-1 {
  height: auto;
  text-align: left;
  font-size: 18px;
  line-height: 16px;
}

label.radio.quiz-alt {
  font-size: 19px;
}

/* Layout Elements */

body.body-1 {
  margin: 0;
  min-width: 10px;
  min-height: 100vw;
  background-color: #f6f4ea;
}

body.ord-color {
  background-color: #bbcac2;
}

body.body-1.intervju {
  background-color: #dee3e0;
}

body.body-1.ord-color {
  background-color: #e2dbeb;
}

body.body-1.quizz-color {
  background-color: #d5d8e6;
}

body.body-1.omoss-color {
  background-color: #dbdcc8;
}

body.body-1.material-color {
  background-color: #dbdcc8;
}

body.body-1.intervjuer-color {
  background-color: #e2dbeb;
}

body.body-1.reg-color {
  background-color: #dbdcc8;
}

.link-container.link-container-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: justify;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 40px;
  padding-top: 0;
  padding-right: 30px;
  padding-left: 0;
  min-width: 33%;
  min-height: 0;
  max-width: 510px;
  width: auto;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
}

.link-container.link-container-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: start;
  -ms-flex-align: start;
  width: 400px;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
}

.link-container.footer.link-container-2 {
  width: auto;
}

.link-container.link-container-3 {
  margin-right: 0;
  margin-left: 0;
  padding-top: 10px;
  width: 250px;
}

.link-container.link-container-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: end;
  margin-top: 10px;
  margin-right: 20px;
  margin-left: 20px;
  padding-right: 0;
  min-height: 60px;
  max-width: none;
  width: 100%;

  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
          justify-content: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
}

.link-container.link-container-5 {
  margin-top: -20px;
  max-width: 1020px;
}

.link-container.link-container-1.two-col {
  min-width: 50%;
}

.link-container.card-guide.link-container-1.two-col {
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 0;
  min-width: 50%;
  max-width: 650px;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
}

.link-container.card-guide-w.link-container-1 {
  margin-bottom: 40px;
}

.link-container.link-container-6 {
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  width: 100%;
  height: 100%;
  background-image: url('https://www.guidepublikutveckling.se/assets/ad/Close-A.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 100% auto;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.link-container.con-link-about {
  margin-top: 20px;
  margin-bottom: 40px;
}

.link-container.con-link-about.h1 {
  margin-top: 40px;
}

.link-container.link-container-1.three-col {
  min-width: 0;
}

.link-container.link-container-8 {
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
}

.link-container.card-guide.j0.link-container-1.two-col {
  margin-bottom: 0;
}

.link-container.alt-link.link-container-4 {
  margin: 0 0 40px;
  padding-right: 30px;
  min-width: 50px;
  min-height: 80px;
  max-width: 650px;
  width: auto;
}

.link-container.alt.card-guide.link-container-1.two-col {
  margin-top: 0;
  margin-bottom: 20px;
}

.link-container.alt-2.card-guide.j0.link-container-1.two-col {
  margin-bottom: 40px;
}

.link-container.con-link-about.space {
  margin-top: 40px;
}

.link-container.card-guide.link-container-1.reg.two-col {
  padding-right: 0;
}

.link-container.card-guide.guide.link-container-1.two-col {
  margin-bottom: 70px;
  padding-right: 30px;
  min-width: 33%;
  max-width: 510px;
  width: auto;
}

.link-container.alt-link.link-container-4.tva-rader {
  margin-top: 20px;
  margin-bottom: 20px;
}

.link-container.link-container-4.vad {
  width: 100%;
}

.link-container.alt-link.link-container-4.space32 {
  margin-bottom: 32px;
  min-height: 50px;
}

.link-container.card-guide.link-container-1.space-bottom.two-col {
  margin-bottom: 20px;
}

.link-container.alt-link.link-container-4.lower.tva-rader {
  margin-top: 0;
}

.link-container.alt-link.link-container-4.tva-rader.upper {
  margin-top: 0;
}

.link-container.card-guide.link-container-1.lower.two-col {
  margin-bottom: 30px;
}

.container-grid.nav-tl.navbar.navbar-dark.navbar-expand-md {
  position: relative;
  z-index: 100;
  -ms-flex-pack: justify;
  -ms-flex-align: center;
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 50px;
  max-width: 1020px;
  width: 95%;
  background-color: #849c8f;

  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
          align-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.container-grid.collapse.navbar-collapse {
  -ms-flex-pack: center;
  -ms-flex-align: start;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 50px;

  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
}

li.list-item-container.nav-item {
  margin-top: 0;
  margin-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
}

ul.list-container.navbar-nav {
  -ms-flex-pack: center;
  -ms-flex-align: center;
  margin-top: 0;
  margin-bottom: 10px;
  padding-top: 0;

  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
}

li.list-item-container.active.nav-item {
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  max-width: none;
  border-bottom: 0 solid #fff;
}

.container-grid.header-bgr {
  padding-top: 10px;
  height: 160px;
  background-color: #45606c;
}

.container-grid.site-title-logo {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -ms-flex-pack: justify;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
  min-height: 50px;
  max-width: 1020px;
  max-height: 50px;
  width: 95%;

  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
}

.container-grid.card {
  margin-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  border-width: 0;
  border-style: none;
  border-radius: 0;
  background-color: transparent;
  background-image: none;
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 0 0;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.container-grid.collapse.show {
  visibility: visible;
  margin-top: 0;
  padding-right: 0;
  padding-bottom: 8px;
  padding-left: 0;
  min-height: 0;
  background-image: url('../Metro-lines-high-t-8.png');
  background-attachment: local;
  background-position: center -38px;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto auto;
  background-repeat: no-repeat;
  opacity: 1;

  background-blend-mode: normal;
}

.container-grid.container-1 {
  position: relative;
  top: -80px;
  z-index: 0;
  float: none;
  clear: none;
  margin-top: 30px;
  background-color: #45606c;
  background-image: none;
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 0 0;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.container-grid.card-header {
  border-bottom-width: 0;
  border-bottom-style: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.container-grid.card-header.card-header-tl {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -ms-flex-pack: end;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0;
  padding-left: 20px;
  min-height: 50px;
  max-width: 1020px;
  width: 95%;
  background-color: #45606c;

  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
     -moz-box-pack: end;
          justify-content: flex-end;
}

.container-grid.card-block {
  display: block;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 50px;
  min-width: 0;
  min-height: auto;
  max-width: 340px;
  max-height: none;
  background-color: transparent;
  background-image: url('../Metro-lines-long-new-c.png');
  background-attachment: fixed;
  background-position: -178px -592px;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: auto auto;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.container-grid.guide-row1 {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: center;
  -ms-flex-align: start;
  margin-top: 0;
  padding-top: 0;
  min-height: 90px;
  max-width: 820px;
  background-color: transparent;

  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  justify-self: auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
          align-self: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
}

.container-grid.container-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  margin-bottom: 0;
  padding-bottom: 2px;
  width: 184px;
  height: 52px;
  border-radius: 26px;
  background-color: #e05439;

  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
          align-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
}

.container-grid.btn-bgr-tl.container-2 {
  margin: 24px 12px 0;
  min-width: 184px;
  min-height: 52px;
  background-color: #e05439;

  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

a.glyph.navbar-toggler {
  margin-top: 0;
  padding-top: 10px;
  padding-bottom: 0;
  min-height: 50px;
  background-image: url('../hamburgermenu_vit.png');
  background-attachment: scroll;
  background-position: center center;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 60% auto;
  background-repeat: no-repeat;
  color: transparent;
  text-align: left;
  font-size: 24px;

  -webkit-text-stroke-color: #fff;
  background-blend-mode: normal;
}

.container-grid.vgr-logo {
  position: absolute;
  top: -800px;
  visibility: visible;
  margin-top: 10px;
  min-width: 190px;
  min-height: 50px;
  max-width: 190px;
  max-height: 50px;
  background-image: url('../VGR_Logga_vit_2103.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 100% auto;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.container-grid.guide-row1.guide-row2 {
  -ms-flex-align: end;

  -webkit-box-align: end;
  -webkit-align-items: flex-end;
     -moz-box-align: end;
          align-items: flex-end;
}

.container-grid.guide-row1.guide-row3 {
  -ms-flex-align: start;

  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
}

.container-grid.pagedivider-up {
  margin-top: -80px;
  height: 8vw;
  background-color: transparent;
  background-image: url('../page-divider-bluegreen-up-300.png');
  background-attachment: local;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 100% auto;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.container-grid.nav-title-tl {
  display: block;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  margin-top: 0;
  padding-top: 13px;
  min-width: 0;
  min-height: 50px;
  max-height: none;
  height: auto;
  background-color: transparent;

  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
          align-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.container-grid.sektion2-content {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -ms-flex-pack: justify;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 300px;
  max-width: 1020px;
  width: 95%;
  background-color: transparent;

  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
     -moz-box-orient: vertical;
     -moz-box-direction: reverse;
}

.container-grid.sektion2-text {
  margin-right: 0;
  min-width: 200px;
  max-width: 700px;
}

.container-grid.container-3 {
  min-width: 200px;
  max-width: 95%;
}

.container-grid.container-4 {
  margin-top: 0;
  padding-top: 70px;
  padding-bottom: 30px;
  min-height: 300px;
  background-color: #f6f4ea;
}

.container-grid.container-5 {
  display: block;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 300px;
  max-width: 1020px;
  width: 95%;
}

.container-grid.sektion2-tl {
  position: static;
  top: -10px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 50px;
  padding-bottom: 70px;
  background-color: #b2a47b;
}

.container-grid.container-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  padding-right: 40px;
  min-height: 30px;
  max-height: 30px;
  height: 30px;

  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
          align-content: flex-end;
}

.container-grid.container-7 {
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  width: 30px;
  height: 30px;
  background-image: url('../pil-svart-60x60-1.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.rule.rule-1 hr {
  border-top-width: 3px;
  border-top-color: #000;
}

.container-grid.two-col-tl {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 50px;
  padding-top: 0;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
}

.container-grid.container-4.sek3-tl {
  padding-top: 40px;
  background-color: #b2a47b;
}

.container-grid.container-4.grey {
  background-color: #575756;
}

.rule.rule-1.white hr {
  border-top-color: #fff;
}

.container-grid.container-7.white {
  background-image: url('../pil-vit-60x60.png');
}

.container-grid.footer {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 40px;
  height: auto;
  background-color: #000;
}

.container-grid.container-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -ms-flex-pack: start;
  -ms-flex-align: center;
  margin: 0 auto 50px;
  max-width: 1020px;
  width: 95%;

  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
     -moz-box-orient: vertical;
     -moz-box-direction: reverse;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
          align-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
}

.container-grid.container-9 {
  margin-right: auto;
  margin-left: auto;
  min-width: 190px;
  min-height: 50px;
  max-width: 195px;
  max-height: 50px;
  background-image: url('../VGR_Logga_vit_2103.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 100% auto;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.container-grid.container-10 {
  margin: -5px 30px 15px 30px;
  padding-top: 0;
  padding-bottom: 0;
  min-width: 10px;
  min-height: 20px;
  max-width: 1200px;
  max-height: 10px;
  width: 200px;
  height: 10px;
  border-right: 0 none #fff;
  border-bottom: .1875rem solid #fff;
}

.container-grid.container-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: start;
  -ms-flex-align: center;
  margin-bottom: 0;
  padding-top: 0;
  width: 250px;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
}

.container-grid.citat-tl {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 40px;
  padding-bottom: 70px;
  background-color: #c3543f;
}

.container-grid.container-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: start;
  -ms-flex-align: center;
  margin-right: auto;
  margin-bottom: -200px;
  margin-left: auto;
  max-width: 1020px;
  width: 95%;

  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
}

div.responsive-picture.picture-1 {
  position: relative;
  left: -14px;
  float: none;
  clear: none;
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
  max-width: 400px;
  background-image: none;
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 0 0;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.container-grid.container-13 {
  position: relative;
  top: -200px;
  float: none;
  clear: none;
  margin-right: auto;
  margin-left: auto;
  padding-top: 0;
  min-width: 200px;
  min-height: 200px;
  max-width: 700px;
}

.container-grid.bluegreen-beige.pagedivider-up {
  position: static;
  margin-top: -80px;
  background-image: url('../page-divider-bluegreen-beige-up-300.png');
  background-size: cover;
}

.container-grid.beige-beige.pagedivider-up {
  margin-top: -40px;
  background-image: url('../page-divider-beige-beige-down-300.png');
  background-size: cover;
}

.container-grid.beige.pagedivider-up {
  margin-top: -60px;
  background-image: url('../page-divider-beige-beige-up-300.png');
  background-size: cover;
}

.container-grid.beige.container-4 {
  padding-top: 40px;
  padding-bottom: 70px;
}

.container-grid.container-4-1 {
  margin-top: 0;
  padding-top: 70px;
  padding-bottom: 30px;
  min-height: 300px;
  background-color: #f6f4ea;
}

.container-grid.container-5-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: start;
  -ms-flex-align: start;
  margin: 0 auto;
  padding: 0;
  min-height: 200px;
  max-width: 1020px;
  background-color: transparent;

  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.container-grid.container-6-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  margin-right: 40px;
  margin-left: 0;
  min-height: 30px;
  max-height: 30px;
  height: 30px;

  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
          align-content: flex-end;
}

.rule.rule-1-1 hr {
  border-top-width: 3px;
  border-top-color: #000;
}

.container-grid.container-7-1 {
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px;
  width: 30px;
  height: 30px;
  background-image: url('../pil-svart-60x60.png');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

.container-grid.sektion2-tl-1 {
  position: static;
  top: -10px;
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 50px;
  padding-bottom: 70px;
  background-color: #b2a47b;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.container-grid.sektion2-tl-1.vad-pub-1 {
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 50px auto 0;
  padding-top: 0;
  padding-bottom: 0;
  max-width: 1020px;
  width: 95%;
  background-color: #f6f4ea;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
}

.container-grid.bluegreen-beige.pagedivider-up.vad-ar {
  background-image: url('../page-divider-bluegreen-lightbeige-up-300.png');
}

.container-grid.card-wrap-tl {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: justify;
  -ms-flex-align: start;
  margin: 30px 0 0;
  padding-right: 0;
  padding-bottom: 20px;
  max-width: none;
  width: 100%;
  border-radius: 12px;
  background-color: #fff;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
          align-content: flex-start;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.container-grid.beige-1.container-4-1.vad-pub {
  margin: 50px auto;
  padding-top: 0;
  padding-bottom: 0;
  min-width: 0;
  max-width: 1020px;
  width: 95%;
}

.container-grid.container-16 {
  padding-top: 0;
  padding-bottom: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.container-grid.container-6-1.pilb {
  margin-right: 20px;
  margin-bottom: 0;
}

.container-grid.container-17 {
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  max-width: 1020px;
  width: 95%;
}

.container-grid.bluegreen-beige.bottom.pagedivider-up.vad-ar {
  margin-top: 0;
  background-image: url('../page-divider-bluegreen-down-300.png');
}

.container-grid.container-18 {
  padding-top: 30px;
  padding-bottom: 40px;
  min-height: 300px;
  background-color: #815a62;
}

.rule.rule-1-1.white hr {
  border-top-color: #fff;
}

.container-grid.container-7-1.white {
  background-image: url('../pil-vit-60x60.png');
}

li.list-item.list-item-1 {
  font-size: 19px;
}

li.list-item.list-item-2 {
  font-size: 19px;
}

.container-grid.container-21 {
  margin-right: auto;
  margin-bottom: 80px;
  margin-left: auto;
  max-width: 600px;
  width: 95%;
  background-color: transparent;
}

li.list-item.list-item-about {
  color: #000;
  font-size: 19px;
  line-height: 32px;
}

.container-grid.quiz-wrap-about {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 30px;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
}

.container-grid.card-wrap-tl.empty.varfor {
  margin-top: 10px;
  padding-bottom: 0;
  min-height: 20px;
  background-color: transparent;
}

.container-grid.guide1-sektion1 {
  padding-top: 30px;
  padding-bottom: 40px;
  background-color: #b2a47b;
}

.container-grid.guide1-sektion3 {
  background-color: #b2a47b;
}

.container-grid.guide1-sekktion4 {
  padding-top: 50px;
  padding-bottom: 20px;
  background-color: #575756;
}

.container-grid.guide1-sektion2 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 50px;
  padding-bottom: 20px;
  background-color: #f6f4ea;
}

.container-grid.container-23 {
  margin-right: auto;
  margin-left: auto;
  max-width: 1020px;
  width: 95%;
}

.container-grid.active-guide.btn-bgr-tl.container-2 {
  background-color: #b2a47b;
}

.container-grid.guide1-sektion4 {
  padding-top: 50px;
  padding-bottom: 20px;
  background-color: #815a62;
}

li.list-item.list-item-guide {
  color: #fff;
  font-size: 18px;
}

li.list-item.black.list-item-guide {
  color: #000;
}

.container-grid.card-wrap.no-space-up-two-col.two-col-tl {
  margin-top: 40px;
}

.container-grid.card-wrap.two-col-tl {
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 600px;
  background-image: none;
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 0 0;
  background-repeat: no-repeat;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  background-blend-mode: normal;
}

.container-grid.container-24 {
  padding: 10px;
  width: 45%;
  border-radius: 12px;
  background-color: #fff;
}

.container-grid.container-25 {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -ms-flex-pack: justify;
  margin-bottom: 40px;
  padding-top: 0;
  max-width: 550px;

  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
}

.container-grid.container-24.lila {
  margin-right: 10px;
  margin-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  min-width: 0;
  min-height: 300px;
  max-width: none;
  max-height: none;
  width: 100%;
  height: auto;
  background-color: #fff;
}

.container-grid.card-wrap-w.guide-3x3.two-col-tl {
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 30px;
  margin-bottom: 0;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
}

.container-grid.card-wrap-w.guide-3x3.sek2and3-no-margin-up.two-col-tl {
  margin-top: 0;
}

.container-grid.container-26 {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: 0;
  margin-bottom: 0;
  min-height: 0;
  max-width: 510px;

  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
          align-content: space-between;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
}

.container-grid.container-24.green {
  margin-right: 0;
  margin-bottom: 20px;
  padding-right: 40px;
  padding-left: 10px;
  min-width: 0;
  min-height: 50px;
  max-width: none;
  max-height: none;
  width: 100%;
  height: auto;
  background-color: #45606c;
}

.container-grid.con-close {
  float: right;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  width: 60px;
  height: 60px;
}

li.list-item.list-item-about.small {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 28px;
}

li.list-item.last.list-item-about.small {
  margin-bottom: 0;
}

div.responsive-picture.picture-2 {
  max-width: none;
  background-image: url('../Varldskulturmuseet.jpg');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 100% auto;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

div.responsive-picture.picture-3 {
  max-width: none;
  background-image: url('../Varbergs%20Teater%20-1.jpg');
  background-attachment: scroll;
  background-position: left top;
  background-clip: border-box;
  background-origin: padding-box;
  background-size: 100% auto;
  background-repeat: no-repeat;

  background-blend-mode: normal;
}

li.list-item a.list-item-text-3 {
  color: #000;
  font-weight: 400;
}

li.list-item a.list-item-text-4 {
  color: #000;
}

.container-grid.container-27 {
  margin-top: 20px;
  margin-bottom: 50px;
  padding: 5px 20px 40px;
  border-radius: 12px;
  background-color: #fff;
}

.container-grid.container-28 {
  margin-top: 20px;
  margin-bottom: 50px;
  padding: 5px 20px;
  border-radius: 12px;
  background-color: #fff;
}

.rule.rule-30-40 {
  margin-top: 30px;
  margin-bottom: 40px;
  padding-top: 0;
  padding-bottom: 0;
}

.rule.rule-30-40 hr {
  border-top-width: 3px;
  border-top-color: #000;
}

.rule.rule-40-40 {
  margin-top: 44px;
  margin-bottom: 40px;
  padding-top: 0;
  padding-bottom: 0;
}

.rule.rule-40-40 hr {
  border-top-width: 3px;
  border-top-color: #000;
}

li.list-item a.list-item-text-5 {
  color: #000;
}

li.list-item a.list-item-text-6 {
  color: #000;
}

li.list-item span.list-item-text-7 {
  font-weight: 500;
}

li.list-item span.list-item-text-8 {
  font-weight: 500;
}

li.list-item span.list-item-text-9 {
  font-weight: 500;
}

li.list-item span.list-item-text-14 {
  font-weight: 500;
}

li.list-item span.list-item-text-15 {
  font-weight: 500;
}

li.list-item span.list-item-text-16 {
  font-weight: 500;
}

li.list-item span.list-item-text-17 {
  font-weight: 500;
}

li.list-item a.list-item-text-3.medium {
  font-weight: 500;
}

li.list-item span.list-item-text-4.medium {
  font-weight: 500;
}

li.list-item a.list-item-text-5.medium {
  font-weight: 500;
}

li.list-item span.list-item-text-3.medium {
  font-weight: 500;
}

li.list-item span.list-item-text-5.medium {
  font-weight: 500;
}

li.list-item span.list-item-text-6.medium {
  font-weight: 500;
}

li.list-item span.list-item-text-10 {
  font-weight: 500;
}

li.list-item a.list-item-text-11 {
  color: #000;
}

li.list-item a.list-item-text-12 {
  color: #000;
}

li.list-item a.list-item-text-13 {
  color: #000;
}

.container-grid.container-28.f1-3 {
  margin-top: 40px;
  padding-top: 5px;
  padding-bottom: 20px;
}

li.list-item a.list-item-text-18 {
  color: #000;
}

.container-grid.container-28.f2 {
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
}

.container-grid.tabell-line {
  margin-top: 5px;
}

.container-grid.wrap-tabell {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
  background-color: transparent;
}

.container-grid.container-28.f2.lower {
  margin-top: 32px;
  margin-bottom: 32px;
  padding-bottom: 40px;
}

.container-grid.f3.tabell-line {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  margin: 0 10px 10px;
  padding: 10px;
  width: 50%;
  background-color: #849c8f;
}

.container-grid.f3.wrap-tabell {
  display: -webkit-box;
  display: -webkit-flex;
  display:    -moz-box;
  display: -ms-flexbox;
  display:         flex;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0;
  border-bottom-width: 0;
  border-bottom-style: solid;
  background-color: transparent;

  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
}

.container-grid.f3.left.tabell-line {
  margin-right: 5px;
  margin-bottom: 0;
  margin-left: 0;
  padding-right: 10px;
  background-color: #f6f4ea;
}

.container-grid.f3.right.tabell-line {
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 5px;
  padding-bottom: 10px;
  background-color: #f6f4ea;
}

.container-grid.container-28.f2.f3 {
  margin-top: 20px;
  padding-bottom: 40px;
}

li.list-item span.list-item-text-19 {
  font-weight: 700;
}

li.list-item span.list-item-text-20 {
  font-weight: 700;
}

li.list-item span.list-item-text-21 {
  font-weight: 700;
}

li.list-item span.list-item-text-22 {
  font-weight: 700;
}

li.list-item span.list-item-text-23 {
  font-weight: 700;
}

li.list-item span.list-item-text-24 {
  font-weight: 700;
}

li.list-item span.list-item-text-25 {
  font-weight: 700;
}

.container-grid.f3.g2.wrap-tabell {
  margin-top: 32px;
}

.container-grid.container-28.f2.f3.h1-2 {
  margin-top: 20px;
  padding-top: 32px;
}

.container-grid.f3.h1-2.wrap-tabell {
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
}

.container-grid.f3.h1-2.right.tabell-line {
  margin-top: 10px;
  margin-left: 0;
  width: 100%;
}

.container-grid.f3.h1-2.left.tabell-line {
  margin-right: 0;
  width: 100%;
}

li.list-item a.list-item-text-26 {
  color: #000;
}

li.list-item a.list-item-text-27 {
  color: #000;
}

li.list-item a.list-item-text-28 {
  color: #000;
}

li.list-item a.list-item-text-29 {
  color: #000;
}

li.list-item a.list-item-text-30 {
  color: #000;
}

li.list-item a.list-item-text-31 {
  color: #000;
}

li.list-item a.list-item-text-32 {
  color: #000;
}

li.list-item a.list-item-text-33 {
  color: #000;
}

div.responsive-picture.i2.picture-2 {
  display: block;
  margin-top: 40px;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  background-color: transparent;
  background-image: url('https://cdn.coffeecupcloud.com/appresources/v1/common/background_online_image.png');
}

.container-grid.container-6.k1-2 {
  -ms-flex-pack: center;
  -ms-flex-align: start;
  margin-top: 10px;

  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
          align-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
     -moz-box-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
}

.container-grid.container-28.f1-3.k1-2 {
  padding-top: 30px;
  padding-bottom: 40px;
}

.container-grid.container-7.k1-2 {
  background-image: url('../pil-beige_vit-down-60x60.png');
}

.rule.k5.rule-40-40 {
  margin-top: 32px;
}

li.list-item a.list-item-text-34 {
  color: #000;
}

.container-grid.container-6.k9 {
  margin-bottom: 32px;
}

.container-grid.container-26.m0 {
  margin-bottom: 20px;
}

.container-grid.container-24.green.lower {
  background-color: #704f81;
}

li.list-item a.list-item-text-36 {
  color: #000;
}

li.list-item a.list-item-text-37 {
  color: #000;
  text-decoration: none;
}

li.list-item a.list-item-text-38 {
  color: #000;
}

.container-grid.container-29 {
  width: 100vw;
}

.rule.intervju.rule-30-40 {
  margin-bottom: -30px;
}

.container-grid.card-wrap-tl.varfor {
  min-width: 0;
}

.container-grid.worksheets-footer {
  margin: 0 auto 50px;
  padding-top: 8px;
  width: 280px;
  height: 80px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  background-color: #fff;
}

.rule.intervjuer.rule-30-40 {
  margin-bottom: 60px;
}

.container-grid.fardplan.guide1-sekktion4 {
  padding-top: 10px;
  padding-bottom: 0;
  background-color: #45606c;
}

.container-grid.container-6-1.vad-ar {
  margin-right: 40px;
  min-width: 0;
  max-width: 650px;
}

.container-grid.container-22 {
  min-width: 50%;
  max-width: 650px;
}

.rule.rule-2 hr {
  border-top-width: 3px;
  border-top-color: #000;
}

.rule.rule-2 {
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 630px;
}

.container-grid.alt.guide1-sektion3 {
  margin-top: 0;
  padding-top: 60px;
  padding-bottom: 30px;
}

.container-grid.alt.container-25 {
  margin-bottom: 20px;
}

.container-grid.container-30 {
  margin-bottom: 40px;
}

.container-grid.container-31 {
  margin-bottom: 40px;
}

.container-grid.container-28.f4 {
  padding-top: 5px;
  padding-bottom: 40px;
}

.container-grid.container-18.green {
  background-color: #45606c;
}

.container-grid.card-wrap.space-upper.two-col-tl {
  margin-top: 70px;
}

.container-grid.bluegreen-beige.bottom.burg.pagedivider-up.vad-ar {
  background-image: url('../page-divider-aubergine-down-300.png');
}

.container-grid.container-30.n-0 {
  margin-bottom: 20px;
}

li.list-item a.list-item-about {
  color: #000;
}

li.list-item a.list-item-text-39 {
  color: #000;
}

li.list-item a.list-item-text-35 {
  color: #000;
}

li.list-item a.list-item-text-40 {
  color: #000;
}

li.list-item a.list-item-text-41 {
  color: #000;
}

li.list-item a.list-item-text-42 {
  color: #000;
}

li.list-item a.list-item-text-43 {
  color: #000;
}

.container-grid.card-wrap-tl.no2 {
  background-color: #5a6381;
}

.container-grid.card-wrap-tl.no3 {
  background-color: #7a6a57;
}

.container-grid.card-wrap-tl.no4 {
  background-color: #436e73;
}

.container-grid.card-wrap-tl.no5 {
  background-color: #718f6b;
}

.container-grid.card-wrap-tl.no6 {
  background-color: #a8a25f;
}

.container-grid.card-wrap-tl.no1 {
  background-color: #6a5977;
}

.container-grid.card-wrap-tl.no7.varfor {
  -ms-flex-pack: justify;
  margin-top: 0;
  background-color: #fff;

  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
}

.container-grid.card-wrap-tl.no8.varfor {
  background-color: #fff;
}

.container-grid.card-wrap-tl.no9.varfor {
  background-color: #fff;
}

.container-grid.beige-1.container-4-1.vad-pub.varfor {
  margin-bottom: 20px;
}

.container-grid.container-14 {
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1020px;
  width: 95%;
}

.container-grid.guide.guide1-sektion2 {
  padding-bottom: 0;
}

.container-grid.container-15 {
  margin-bottom: 0;
}

div.responsive-picture.picture-2.portrait {
  margin-top: 30px;
}

.container-grid.card-wrap.no-space-up.two-col-tl {
  margin-top: 20px;
}

.container-grid.alt.bg-color.guide1-sektion3 {
  background-color: #ceb1c4;
}

.container-grid.container-32 {
  margin-bottom: 0;
}

.container-grid.spacedown28 {
  margin-bottom: 10px;
}

.rule.rule-2.upper {
  margin-top: 40px;
}

.container-grid.alt.bg-color-2.guide1-sektion3 {
  background-color: #bbcac2;
}

.container-grid.card-wrap.no-space-up.space-down-ombord.two-col-tl {
  margin-bottom: 20px;
}

div.responsive-video.video-platform-1 {
  margin-top: 40px;
}

.container-grid.container-33 {
  margin-bottom: 20px;
}

a.glyph.navbar-toggler:visited {
  color: transparent;
}

a.glyph.navbar-toggler:focus {
  color: transparent;
}

.container-grid.btn-bgr-tl.container-2:hover {
  background-color: #b2a47b;
}

a.glyph.navbar-toggler:hover {
  color: transparent;
}

li.list-item a.list-item-text-3:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-4:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-5:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-6:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-11:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-12:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-13:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-18:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-26:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-27:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-28:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-29:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-30:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-31:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-32:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-33:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-34:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-36:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-37:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-38:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-39:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-35:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-40:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-41:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-42:hover {
  text-decoration: underline;
}

li.list-item a.list-item-text-43:hover {
  text-decoration: underline;
}

li.list-item-container.active.nav-item:hover {
  border-bottom-width: 0;
  border-bottom-color: #fff;
}

.container-grid.btn-bgr-tl.container-2:active {
  background-color: #b2a47b;
}

a.glyph.navbar-toggler:active {
  color: transparent;
}

@media screen and (min-width: 36rem) {
  /* Text Elements */

  h1.bigbig {
    font-size: 15.67vw;
  }

  h1.bigbig-1 {
    font-size: 15.67vw;
  }

  h2.citat.h2-bold {
    font-size: 12.88vw;
    line-height: 12.88vw;
  }

  p.paragraph.ingress.paragraph-1 {
    margin-top: 50px;
  }

  p.paragraph.left.p-standard-about.tabell {
    width: 60%;
  }

  p.paragraph.p-standard-about.right.tabell {
    margin-right: 40px;
    max-width: none;
    width: 30%;
  }

  p.paragraph.p-std.spacedown28 {
    margin-bottom: 0;
  }

  /* Layout Elements */

  .link-container.link-container-4 {
    min-height: 60px;
  }

  .link-container.alt-link.link-container-4.space-two-rows.space32 {
    margin-bottom: 8px;
  }

  li.list-item-container.nav-item {
    margin-bottom: 20px;
  }

  .container-grid.nav-tl.navbar.navbar-dark.navbar-expand-md {
    width: 95%;

    -webkit-align-content: center;
    -ms-flex-line-pack: center;
            align-content: center;
  }

  .container-grid.btn-bgr-tl.container-2 {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .container-grid.guide-row1.guide-row3 {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
  }

  .container-grid.guide-row1.guide-row2 {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: end;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
            align-content: flex-start;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
            justify-content: flex-end;
  }

  .container-grid.guide-row1 {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: center;
    -ms-flex-align: start;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
            align-content: flex-end;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
  }

  a.glyph.navbar-toggler {
    margin-top: 0;
  }

  .container-grid.card-block {
    margin-right: auto;
    margin-left: auto;
    background-position: -88px -592px;
  }

  .container-grid.vgr-logo {
    top: -800px;
    visibility: visible;
    margin-top: 0;
  }

  .container-grid.header-bgr {
    height: 160px;
  }

  .container-grid.container-1 {
    top: -80px;
    margin-top: 30px;
  }

  .container-grid.pagedivider-up {
    margin-top: -80px;
  }

  .container-grid.container-10 {
    max-height: none;
  }

  .container-grid.card-wrap-tl {
    width: 100%;
  }

  .container-grid.f3.h1-2.left.tabell-line {
    margin-right: 5px;
    width: 50%;
  }

  .container-grid.f3.h1-2.right.tabell-line {
    margin-top: 0;
    margin-left: 5px;
    width: 50%;
  }

  .container-grid.f3.h1-2.wrap-tabell {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
  }
}

@media screen and (min-width: 39.0625rem) {
  /* Text Elements */

  h1.heading-1.site-title {
    font-size: 38px;
  }

  h1.bigbig.bigbigbig {
    font-size: 120px;
    line-height: 115px;
  }

  h1.bigbig {
    font-size: 15.67vw;
  }

  h1.bigbig-1 {
    font-size: 15.67vw;
  }

  h1.h1-overrubrik-guide {
    font-size: 48px;
    line-height: 52px;
  }

  h1.h1-about {
    font-size: 56px;
    line-height: 62px;
  }

  h2.citat.h2-bold {
    font-size: 80px;
    line-height: 80px;
  }

  p.paragraph.footer.p-std.white {
    text-align: right;
  }

  p.paragraph.p-std.white {
    margin-top: 0;
  }

  /* Layout Elements */

  .link-container.footer.link-container-2 {
    margin-top: 0;
  }

  .link-container.link-container-5 {
    max-width: none;
    width: 100%;

    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
            align-self: auto;
  }

  .link-container.link-container-4 {
    min-height: 60px;
    width: 100%;
  }

  .link-container.card-guide.guide.link-container-1.two-col {
    max-width: 510px;
  }

  .container-grid.guide-row1.guide-row3 {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: start;
    -ms-flex-align: center;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
  }

  .container-grid.guide-row1.guide-row2 {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: center;
    -ms-flex-align: center;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
  }

  .container-grid.guide-row1 {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: end;
    -ms-flex-align: center;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
  }

  .container-grid.collapse.show {
    position: static;
    background-position: -80px 708px;
  }

  .container-grid.card-block {
    margin-right: 0;
    margin-left: 0;
    max-width: none;
    background-attachment: local;
    background-position: -88px -93px;
  }

  .container-grid.vgr-logo {
    position: static;
    background-attachment: scroll;
  }

  .container-grid.header-bgr {
    padding-top: 30px;
  }

  .container-grid.container-1 {
    top: -50px;
  }

  .container-grid.site-title-logo {
    margin-bottom: 10px;
  }

  .container-grid.pagedivider-up {
    margin-top: -60px;
  }

  .container-grid.btn-bgr-tl.container-2 {
    margin-top: 0;
    margin-bottom: 12px;
  }

  .container-grid.container-8 {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: center;
    -ms-flex-align: center;
    margin-bottom: 40px;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
            align-content: flex-start;
  }

  .container-grid.container-10 {
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
    margin-top: 0;
    margin-bottom: 0;
    min-height: auto;
    max-width: none;
    width: auto;
    height: 100px;
    border-right-width: .1875rem;
    border-right-style: solid;
    border-bottom-width: 0;
    border-bottom-style: none;
  }

  .container-grid.container-11 {
    -ms-flex-align: start;
    padding-top: 10px;

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
  }

  .container-grid.container-12 {
    -ms-flex-pack: start;

    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
  }

  .container-grid.bluegreen-beige.pagedivider-up {
    margin-top: -50px;
  }

  .container-grid.sektion2-tl {
    padding-top: 70px;
  }

  .container-grid.sektion2-tl-1 {
    padding-top: 70px;
  }

  .container-grid.container-5-1 {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-pack: start;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
            justify-content: flex-start;
  }

  .container-grid.card-wrap-tl {
    margin-right: 0;
    max-width: none;
  }

  .container-grid.container-17 {
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: 100px;
    max-width: none;
    width: 95%;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .container-grid.container-5-1.varfor {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .container-grid.guide1-sektion1 {
    padding-top: 30px;
    padding-bottom: 50px;
  }

  .container-grid.guide1-sektion2 {
    padding-top: 70px;
    padding-bottom: 40px;
  }

  .container-grid.guide1-sekktion4 {
    padding-top: 70px;
    padding-bottom: 40px;
  }

  .container-grid.guide1-sektion4 {
    padding-top: 70px;
    padding-bottom: 40px;
  }

  .container-grid.container-26 {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    max-width: 510px;

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
  }

  .container-grid.nav-title-tl {
    padding-top: 14px;
  }
}

@media screen and (min-width: 48rem) {
  /* Text Elements */

  h1.bigbig {
    font-size: 120px;
    line-height: 92px;
  }

  h1.big.bigbig {
    font-size: 104px;
  }

  h1.bigbig-1 {
    font-size: 120px;
  }

  p.paragraph.ingress {
    max-width: 800px;
  }

  p.paragraph.p-std.strategi {
    margin-right: 0;
    text-align: left;
  }

  p.paragraph.p-standard.paragraph-4.white {
    padding-bottom: 0;
  }

  p.paragraph.p-std {
    padding-right: 0;
  }

  a.link-text.nav-link.nav-link-tl {
    padding-bottom: 0;
    border-top: .25rem solid transparent;
    border-bottom: .25rem solid transparent;
  }

  a.link-text.nav-link.nav-link-tl:hover {
    border-top: .25rem solid transparent;
    border-bottom: .25rem solid #fff;
  }

  /* Layout Elements */

  .link-container.link-container-1 {
    margin-bottom: 0;
  }

  .link-container.link-container-5 {
    max-width: none;
  }

  .link-container.card-guide.link-container-1.two-col {
    margin-bottom: 40px;
  }

  .link-container.card-guide-w.link-container-1 {
    margin-bottom: 40px;
    padding-bottom: 0;
  }

  .link-container.link-container-1.three-col {
    min-width: 33%;
    max-width: 33%;
  }

  .link-container.link-container-4 {
    min-height: 60px;
  }

  .link-container.link-container-4.no1 {
    width: auto;
  }

  .link-container.card-guide.guide.link-container-1.two-col {
    padding-right: 0;
    max-width: 650px;
  }

  .link-container.link-container-4.vad {
    width: auto;
  }

  .container-grid.collapse.navbar-collapse {
    -ms-flex-align: center;
    margin-right: 0;
    margin-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
  }

  li.list-item-container.nav-item {
    margin-top: 0;
    margin-bottom: 0;
  }

  .container-grid.nav-tl.navbar.navbar-dark.navbar-expand-md {
    -ms-flex-pack: end;
    -ms-flex-align: start;

    -webkit-align-content: center;
    -ms-flex-line-pack: center;
            align-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
            justify-content: flex-end;
  }

  ul.list-container.navbar-nav {
    -ms-flex-align: start;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
  }

  li.list-item-container.active.nav-item {
    margin-top: 0;
  }

  .container-grid.guide-row1 {
    margin-right: auto;
    margin-left: auto;
    max-width: 820px;
    max-height: none;
  }

  .container-grid.guide-row1.guide-row2 {
    -ms-flex-pack: center;
    max-width: 820px;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
  }

  .container-grid.card-block {
    margin-right: 0;
    margin-left: 0;
  }

  .container-grid.nav-title-tl {
    position: absolute;
    top: -300px;
  }

  .container-grid.two-col-tl {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
  }

  .container-grid.container-4 {
    padding-bottom: 70px;
  }

  .container-grid.container-12 {
    -ms-flex-align: start;

    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
            align-items: flex-start;
  }

  .container-grid.beige.container-4 {
    padding-bottom: 100px;
  }

  .container-grid.container-4-1 {
    padding-bottom: 70px;
  }

  .container-grid.container-5-1 {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
  }

  .container-grid.card-wrap-tl {
    margin-right: 20px;
    min-width: 49%;
    min-height: 600px;
    width: auto;
  }

  .container-grid.container-6-1.pilb {
    margin-right: 20px;
  }

  .container-grid.container-5-1.varfor {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .container-grid.card-wrap.two-col-tl {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
  }

  .container-grid.card-wrap-w.two-col-tl {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
  }

  .container-grid.card-wrap-w.guide-3x3.two-col-tl {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-top: 30px;
    margin-bottom: 20px;

    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
  }

  .container-grid.container-26 {
    max-width: 760px;

    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .container-grid.container-24.lila {
    margin-right: 10px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .container-grid.container-24.green {
    margin-right: 10px;
    padding-right: 10px;
    padding-left: 10px;
    background-color: #45606c;
  }

  .container-grid.con-close {
    margin-top: 40px;
    margin-right: 20px;
    margin-left: 0;
  }

  .container-grid.card-wrap-tl.varfor {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    min-width: 49%;
    max-width: 50%;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .container-grid.container-19 {
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
  }

  .container-grid.container-6-1 {
    margin-right: 0;
    width: 100%;
  }

  .container-grid.card-wrap-tl.empty.varfor {
    width: 50%;
  }

  .container-grid.container-6-1.vad-ar {
    margin-right: 40px;
  }

  .container-grid.navbar-collapse {
    padding-top: 0;
  }

  .container-grid.container-16 {
    padding-bottom: 0;
  }

  .container-grid.card-wrap-tl.no6 {
    min-height: 430px;
  }

  .container-grid.card-wrap-tl.no5 {
    min-height: 430px;
  }

  .container-grid.card-wrap-tl.no4 {
    min-height: 440px;
  }

  .container-grid.card-wrap-tl.no3 {
    min-height: 440px;
  }

  .container-grid.card-wrap-tl.no2 {
    min-height: 505px;
  }

  .container-grid.card-wrap-tl.no1 {
    min-height: 505px;
  }

  .container-grid.card-wrap-tl.no7.varfor {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
  }

  .container-grid.card-wrap-tl.no8.varfor {
    margin-top: 0;
  }

  .container-grid.container-6 {
    padding-right: 0;
  }

  .container-grid.collapse.navbar-collapse:hover {
    position: static;
    top: 0;
    -ms-flex-align: center;
    padding-top: 0;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
  }
}

@media screen and (min-width: 62rem) {
  /* Text Elements */

  h1.big.bigbig {
    font-size: 120px;
  }

  h1.bigbig-1 {
    line-height: 115px;
  }

  h1.big-1.bigbig-1.vad-pub.white-1 {
    font-size: 113px;
    line-height: 114px;
  }

  h2.citat.h2-bold {
    margin-bottom: 40px;
    color: #fff;
    text-align: left;
    font-weight: 400;
    font-size: 80px;
    line-height: 80px;
  }

  h2.heading-3 {
    margin-top: 30px;
    margin-right: 10px;
    margin-left: 20px;
    padding-right: 0;
    font-weight: 400;
    font-size: 30px;
  }

  h2.h2-bold:hover {
    text-decoration: none;
  }

  p.paragraph.ingress.paragraph-1 {
    margin-top: 0;
    margin-right: 50px;
  }

  p.paragraph.paragraph-4 {
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 20px;
  }

  p.paragraph.no-space-lower.p-standard-bold.white:hover {
    color: #fff;
  }

  p.paragraph.link-text-about.p-standard-about:hover {
    color: #000;
  }

  a.link-text.nav-link.nav-link-tl {
    color: #fff;
  }

  a.link-text.nav-link.nav-link-tl:focus {
    border-bottom-color: #fff;
    font-weight: 400;
    font-size: 17px;
  }

  a.link-text.nav-link.nav-link-tl:hover {
    color: #fff;
  }

  a.link-text.nav-link.nav-link-tl:active {
    border-bottom-color: #fff;
    font-weight: 400;
    font-size: 17px;
  }

  /* Layout Elements */

  .link-container.link-container-5 {
    margin-top: 0;
  }

  .container-grid.sektion2-content {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
  }

  .container-grid.container-3 {
    max-width: 320px;
  }

  .container-grid.sektion2-tl {
    margin-top: 0;
  }

  .container-grid.beige.container-4 {
    padding-top: 40px;
  }

  .container-grid.sektion2-tl-1 {
    margin-top: 0;
  }

  .container-grid.container-17 {
    -ms-flex-pack: center;
    max-width: 1020px;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
  }

  .container-grid.container-5-1.varfor {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .container-grid.container-19 {
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
    width: 100%;

    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .container-grid.container-20 {
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
    width: 100%;
  }

  .container-grid.card-wrap-tl.varfor {
    width: 50%;
  }

  .container-grid.card-wrap-tl.empty.varfor {
    background-color: transparent;
  }

  .container-grid.footer {
    padding-top: 0;
  }

  .container-grid.card-wrap-tl.no6 {
    min-height: 365px;
  }

  .container-grid.card-wrap-tl.no5 {
    min-height: 365px;
  }

  .container-grid.card-wrap-tl.no4 {
    min-height: 385px;
  }

  .container-grid.card-wrap-tl.no3 {
    min-height: 385px;
  }

  .container-grid.card-wrap-tl.no2 {
    min-height: 425px;
  }

  .container-grid.card-wrap-tl.no1 {
    min-height: 425px;
  }

  .container-grid.fardplan.guide1-sekktion4 {
    padding-top: 10px;
    padding-bottom: 0;
  }

  .rule.rule-1 hr {
    border-top-color: #000;
  }

  .container-grid.container-24 {
    background-color: #fff;
  }
}

@media screen and (min-width: 75rem) {
  /* Text Elements */

  h1.bigbig.bigbigbig.white {
    margin-bottom: 8px;
  }

  h1.big-1.bigbig-1.vad-pub.white-1 {
    font-size: 120px;
    line-height: 115px;
  }

  h1.h1-overrubrik-guide {
    font-weight: 300;
    font-size: 48px;
    line-height: 52px;
  }

  h1.h1-overrubrik-guide.white {
    color: #fff;
  }

  h1.h1-about {
    color: #000;
  }

  h2.h2-regular.heading-3.white {
    color: #fff;
  }

  p.paragraph.p-standard {
    margin-top: 0;
    padding-bottom: 50px;
    font-size: 19px;
    line-height: 32px;
  }

  p.paragraph.ingress {
    max-width: 800px;
    color: #000;
    font-size: 28px;
    line-height: 42px;
  }

  p.paragraph a.paragraph-text-88 {
    color: #000;
  }

  p.paragraph a.paragraph-text-89 {
    color: #000;
  }

  p.paragraph a.paragraph-text-90 {
    color: #000;
  }

  p.paragraph a.paragraph-text-91 {
    color: #000;
  }

  p.paragraph a.paragraph-text-92 {
    color: #000;
  }

  p.paragraph span.h2-regular {
    font-weight: 700;
    font-size: 1.25rem;
  }

  p.paragraph span.paragraph-text-7 {
    font-weight: 700;
    font-size: 1.25rem;
  }

  p.paragraph.alt.ingress-guide.no-space-up {
    margin-top: 0;
  }

  p.paragraph.down28.p-std.space-down {
    margin-bottom: 28px;
  }

  p.paragraph a.paragraph-text-88:hover {
    text-decoration: underline;
  }

  p.paragraph a.paragraph-text-89:hover {
    text-decoration: underline;
  }

  p.paragraph a.paragraph-text-90:hover {
    color: #000;
    text-decoration: underline;
  }

  p.paragraph a.paragraph-text-91:hover {
    text-decoration: underline;
  }

  p.paragraph a.paragraph-text-92:hover {
    text-decoration: underline;
  }

  /* Layout Elements */

  .link-container.link-container-1 {
    min-width: 10px;
  }

  .link-container.link-container-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 10px 20px 0;
    min-height: 60px;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
            align-content: space-between;
  }

  .link-container.card-guide-w.link-container-1 {
    min-width: 33%;
  }

  .link-container.link-container-1.three-col {
    min-width: 33%;
  }

  .link-container.alt-link.link-container-4.space32 {
    margin-bottom: 32px;
  }

  .container-grid.collapse.navbar-collapse {
    -ms-flex-pack: center;

    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
  }

  .container-grid.nav-tl.navbar.navbar-dark.navbar-expand-md {
    background-color: #849c8f;
  }

  .container-grid.container-7 {
    background-image: url('../pil-svart-60x60-1.png');
  }

  .container-grid.container-7.white {
    background-image: url('../pil-vit-60x60.png');
  }

  .container-grid.pagedivider-up {
    background-image: url('../page-divider-beige-beige-up-300.png');
  }

  .container-grid.beige-beige.pagedivider-up {
    background-image: url('../page-divider-beige-beige-down-300.png');
  }

  .container-grid.container-4 {
    padding-top: 70px;
  }

  .container-grid.beige.container-4 {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .container-grid.beige.pagedivider-up {
    margin-top: -60px;
  }

  .container-grid.container-4.sek3-tl {
    padding-top: 50px;
  }

  .container-grid.bluegreen-beige.pagedivider-up {
    background-image: url('../page-divider-bluegreen-beige-up-300.png');
  }

  .container-grid.container-4-1 {
    padding-top: 70px;
  }

  .container-grid.container-7-1 {
    background-image: url('../pil-svart-60x60.png');
  }

  .container-grid.container-5-1 {
    background-color: transparent;
  }

  .container-grid.container-16 {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .container-grid.container-20 {
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .container-grid.card-wrap-w.two-col-tl {
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
  }

  .container-grid.card-wrap.two-col-tl {
    -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .container-grid.guide1-sektion4 {
    background-color: #815a62;
  }

  .container-grid.card-wrap-tl {
    border-radius: 12px;
    background-color: #6a5977;
  }

  .container-grid.card-wrap-tl.no3 {
    min-height: 355px;
    background-color: #7a6a57;
  }

  .container-grid.card-wrap-tl.no4 {
    min-height: 355px;
    background-color: #436e73;
  }

  .container-grid.card-wrap-tl.no5 {
    min-height: 340px;
    background-color: #718f6b;
  }

  .container-grid.card-wrap-tl.no6 {
    min-height: 340px;
    background-color: #a8a25f;
  }

  .container-grid.card-wrap-tl.no7.varfor {
    margin-top: 0;
  }

  .container-grid.card-wrap-tl.no8.varfor {
    margin-top: 0;
  }

  .container-grid.beige-1.container-4-1.vad-pub.varfor {
    margin-top: 20px;
  }

  .coffeebuilder_focus {
    font-size: 1.125rem;
  }

  .container-grid.fardplan.guide1-sekktion4 {
    padding-top: 10px;
    padding-bottom: 0;
  }

  .container-grid.card-wrap-w.guide-3x3.two-col-tl {
    margin-top: 30px;
  }

  .container-grid.container-14 {
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1020px;
    width: 95%;
  }
}
